<template>
    <div class="about">
        <v-header />
        <v-sidebar />
        <div class="content-box">
            <!-- <v-tags></v-tags>-->
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <!--<keep-alive>-->
                            <component :is="Component" />
                        <!--</keep-alive>-->
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
        <div class="footer">
            <p>客服电话 4000082688 </p>
            <p>©CopyRight 2021 京ICP20026331号-2 运动马达科技开发</p>
        </div>
    </div>
</template>
<script>
// import { computed } from "vue";
// import { useStore } from "vuex";
import vHeader from "@/components/Header.vue";
import vSidebar from "@/components/Sidebar.vue";

export default {
    components: {
        vHeader,
        vSidebar,
        // vTags,
    },

};
</script>
<style scoped>
.footer{
  height:60px;
  position:absolute;
  z-index:999;
  bottom:0;
  left:200px;
  right:0;
  border-top:1px solid #ddd;
  background:#fff;
}
.footer p{
  text-align:center;
  padding-top:10px;
}
</style>
