<template>
    <div class="sidebar">
        <div class="logo">
        <!--<img src="../assets/img/logo.png" /><b>LOGO</b>-->
        <br />
        </div>
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse"
            active-text-color="#20a0ff" unique-opened router>
            <template v-for="item in items">
                <template v-if="item.subs">
                    <!--<el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                    {{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}
                            </el-menu-item>
                        </template>
                    </el-submenu>-->
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>

    </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
    setup() {
        const items = [
            {
                icon: "el-icon-s-home",
                index: "/",
                title: "首页",
            },

            {
                icon: "el-icon-tickets",
                index: "/order/search",
                title: "订单",
            },
            {
                icon: "el-icon-notebook-2",
                index: "/instructions/explain",
                title: "银行手册",
            },
            {
                icon: "el-icon-office-building",
                index: "/order/companyinfo",
                title: "公司介绍",
            }
            

        ];

        const route = useRoute();

        const onRoutes = computed(() => {
            return route.path;
        });

        const store = useStore();
        const collapse = computed(() => store.state.collapse);

        return {
            items,
            onRoutes,
            collapse,
        };
    },
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0;
    overflow-y: scroll;
    background:linear-gradient(to bottom,#f7790b,#f56a19,#ee2507);
    box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
}
.logo{
    text-align:center;
}
.logo b{
    font-size:18px;
    color:#fff;
}
.logo img{
    width:38px;
    height:38px;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 200px;
}
.sidebar-el-menu{
    background:linear-gradient(to bottom,#f7790b,#f56a19,#ee2507);

}
.el-menu-item{
    color:#fff;
}
.el-menu-item i{
    color:#fff;
}
.sidebar > ul {
    height: 100%;
}
.el-menu-item.is-active{
    background:linear-gradient(to right,#f98f36,#f86616,#f74a00) !important;
    border-top:1px transparent solid;
    border-bottom:1px transparent solid;
    border-image:linear-gradient(to right,#f74a00,#f98e36) 1 10;
    color:#fff !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background:#f76d14;
}
.el-menu{
    border-right:0;
}
</style>
